import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";

function Premios() {
	return (
		<>
			<Container>
				<Row className="text-center mt-5">
					<Col className="ml-auto mr-auto" md="12">
						<h2 className="title">Premios Espectaculares</h2><br/>
						<h5 className="">
							Recordá que todos los premios serán entregados. Cada número vendido es una
							oportunidad para ganar uno de los siguientes premios:
						</h5><br/>
						<h4> 
							1° - Dos trekking <a href="https://expedicionandina.com.ar/expedicion-andina/" target="_blank" rel="noreferrer">Expedición Andina</a>.<br/>
							2° - Un costillar completo de <a href="https://www.instagram.com/laboutiquedelcerdosanrafael" target="_blank" rel="noreferrer">La Boutique del Cerdo</a> (San Rafael).<br/> 
							3° - Dos noches de alojamiento en Las Leñas (para 4 o 5 personas).<br/>
							4° - Boucher de $20,000 para <a href="https://www.instagram.com/hayequipodeportes" target="_blank" rel="noreferrer">Hay Equipo</a> (General Alvear).<br/>
							5° - Consumición por $10,000 en <a href="https://www.instagram.com/antidoto.gintoneria" target="_blank" rel="noreferrer">Antídoto Gintonería</a> (San Rafael)<br/> 
							6° - Crema de Enebro de "JUST".<br/>
							7° - Set de artículos de <a href="https://www.instagram.com/disenomasarqui/" target="_blank" rel="noreferrer">Diseño +arq</a> (San Rafael).<br/>
							8° - Combo de productos integrales de <a href="https://www.facebook.com/SaborCaseroConfiteriaCafe/" target="_blank" rel="noreferrer">Sabor Casero</a> (San Rafael).<br/> 
							9° - Sesión de Podología "Virginia".<br/>
							10° - Buzo de micropolar de "Paola Cibantos".<br/>
							11° a 16° - Gin Aconcagua con un copón de "Distribuidora Mendoza Drinks".<br/>
							17° - Sesión de masajes "Espacio Lotus". <br/>
							18° - Respaldo Sommier “Del Rosario Muebles”. <br/>
							19° - Sesión de Masajes. “Quererte Masoterapia”. <br/>
							20° - Kit reglamentario universal para automotor de “Beto repuestos”. <br/>
							21° - Jamón Crudo <br/><br/>

						</h4><br/>
					</Col>
					<Col md="12">
						<Button
							className="mx-2 btn-round"
							color="info"
							href="/Sorteo-Aconcagua-Con-Sentidos.pdf"
							size="lg"
							target="_blank"
						>
							<i className="now-ui-icons arrows-1_share-66 mr-1"></i>
							Lista de premios en PDF
						</Button>

						<Button color="info" className="mx-2 btn-round" size="lg" tag={Link} onClick={(e) => {
							document.getElementById("instrucciones").scrollIntoView({ behavior: "smooth" });
						}}
						>
							<div><i className="hover:animate-bounce now-ui-icons arrows-1_minimal-down"></i> Instrucciones </div>
						</Button>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Premios;
