import React from "react";
import {Container, Row, Col } from "reactstrap";

function Noticias() {
	return (
		<>
			<Container>
				<Row className="text-center mt-5">
					<Col className="ml-auto mr-auto" md="12">
						<h4> 
							Los Andes: <a href="https://www.losandes.com.ar/sociedad/walter-y-silvina-son-ciegos-atletas-y-padres-de-tres-hijas-a-fin-de-ano-queremos-subir-el-aconcagua/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">A fin de año queremos subir el Aconcagua</a>
							<iframe src="https://www.losandes.com.ar/sociedad/walter-y-silvina-son-ciegos-atletas-y-padres-de-tres-hijas-a-fin-de-ano-queremos-subir-el-aconcagua/?ref=aconcaguaconsentidos" width="600" height="300"></iframe>
							<br/><br/> 
							Portada: <a href="https://portada.com.ar/el-sentido-de-hacer-cumbre-en-el-aconcagua-del-primer-mendocino-ciego/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">El sentido de hacer cumbre en el Aconcagua del primer mendocino ciego</a>
							<iframe src="https://portada.com.ar/el-sentido-de-hacer-cumbre-en-el-aconcagua-del-primer-mendocino-ciego/?ref=aconcaguaconsentidos" width="600" height="300"></iframe>
							<br/><br/>
							La Voz: <a href="https://www.lavoz.com.ar/deportes/y-mas/maraton-cordoba-42k-la-ciudad-vivio-otra-fiesta-y-pedro-gomez-repitio-su-festejo/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">Maratón Córdoba 42K: la ciudad vivió otra fiesta y Pedro Gómez repitió su festejo</a><br/> 
							<iframe src="https://www.lavoz.com.ar/deportes/y-mas/maraton-cordoba-42k-la-ciudad-vivio-otra-fiesta-y-pedro-gomez-repitio-su-festejo/?ref=aconcaguaconsentidos" width="600" height="300"></iframe>
							<br/> <br/>
							LV Diez: <a href="https://www.lvdiez.com.ar/clima-de-radio/pablo-tapia-y-walter-alvarez-el-joven-no-vidente-rumbo-al-aconcagua/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">Pablo Tapia y Walter Álvarez, el joven no vidente rumbo al Aconcagua</a><br/>
							<iframe src="https://www.lvdiez.com.ar/clima-de-radio/pablo-tapia-y-walter-alvarez-el-joven-no-vidente-rumbo-al-aconcagua/?ref=aconcaguaconsentidos" width="600" height="300"></iframe>
							<br/><br/>
							Info Running ARG: <a href="https://www.instagram.com/p/CnU54Atp6cR/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">Walter y Silvina sueñan con subir al Aconcagua a fin de año</a> 
							<br/><br/>
							TVA General Alvear: <a href="https://fb.watch/gmTXLNGTXa/?ref=aconcaguaconsentidos" 
							target="_blank" rel="noreferrer">¡150 parejas participaron de la 1° posta "Juntos a la Par"!</a>
							<br/> <br/>
						</h4><br/>
					</Col>

				</Row>
			</Container>

		</>
	);
}

export default Noticias;
