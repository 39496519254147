import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

function Instrucciones() {
  const [copied, setCopied] = React.useState(false);
  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="instrucciones"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">¿Cómo Participar?</h2>
              <h5 className="title">
                Paso 1: Elegí tu Número
              </h5>
              <p>Solicitá tu número a los vendedores enviando un <br></br> WhatsApp al (2625) 445 830 o al (2604)  018 496</p>
              <p>Cada número tiene un valor de 1000 pesos.
                Podés adquirir 3 números por tan solo 2000 pesos, ¡una oferta especial!</p><br></br>

              <h5 className="title">
                Paso 2: Realizá tu Transferencia</h5>
              <p>
                Realizá una transferencia al alias
                <CopyToClipboard text={"alwalter"} 
                  onCopy={() => setCopied(true)}>
                  <Button id="copy-btn" size="lg" color="info" className="py-2 px-3 mx-2 rounded-md shadow-xl">
                    alwalter

                    <i className="now-ui-icons files_single-copy-04 mx-1"></i>
                    {copied ? <span style={{ color: '#22c55e' }}>
                      <i className="now-ui-icons ui-1_check mx-1"></i></span> : null}

                  </Button>
                </CopyToClipboard>

                <UncontrolledTooltip target="#copy-btn">
                  Copiar
                </UncontrolledTooltip>

                con el monto correspondiente a la cantidad de números que deseás comprar.
              </p>
              <p>
              Titular de la cuenta: ALVAREZ BLANCO WALTER DAVID
                </p><br></br>
              <h5 className="title">Paso 3: Enviá tus Datos </h5>
              <p>
                Después de hacer la transferencia, envia tus datos al vendedor con la siguiente información:
                <br></br>
                <br></br>Asunto: Participación en la Rifa Aconcagua con sentidos.
                <br></br>- Nombre completo.
                <br></br>- Comprobante de la transferencia.
              </p><br></br>

              <h5 className="title">
                Paso 4: Sorteo en Redes Sociales</h5>
                <p>Se sortearán todos los números vendidos</p>
                <p>Realizaremos un vivo por las redes sociales </p>
            </Col>

          </Row>
          <br></br>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>¡Gracias por tu apoyo y generosidad! Juntos, podemos hacer posible el sueño de Walter de conquistar el Aconcagua.</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">

              <Button
                className="btn-neutral btn-icon btn-round"
                color="facebook"
                href="https://www.facebook.com/walter.al.aconcagua?ref=aconcaguaconsentidos"
                id="tooltip735272548"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-facebook-square"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip735272548">
                Seguinos
              </UncontrolledTooltip>

              <Button
                className="btn-neutral btn-icon btn-round"
                color="instagram"
                href="https://www.instagram.com/walter.al.aconcagua?ref=aconcaguaconsentidos"
                id="tooltip331904895"
                size="lg"
                target="_blank"
              >
                <i className="fab fa-instagram"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip331904895">
                Seguinos
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Instrucciones;
