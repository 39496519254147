import React from "react";
import { Col, Row } from "reactstrap";

function Header() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    < >
      <div className="page-header page-header-small" >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg6.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center" >
            <Row  >
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">Noticias Destacadas</h1>
                
                <p className="text-2xl" >
                En esta sección, exploramos las entrevistas y coberturas mediáticas 
                que documentan la preparación meticulosa de nuestro valiente atleta 
                ciego para enfrentar el desafío del Aconcagua. Desde actividades 
                deportivas hasta conversaciones sobre su entrenamiento, sumérgete en 
                las experiencias narradas por varios medios y plataformas de redes 
                sociales mientras destacamos las voces que han compartido su inspiradora historia.
                </p>
                
              </Col>
              
            </Row>
        </div>
      </div>
    </>
  );
}

export default Header;
