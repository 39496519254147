
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "pages/Index";
import Sorteo from "pages/Sorteo";
import News from "pages/News";

//const root = ReactDOM.createRoot(document.getElementById("root"));
const root = document.getElementById("root");

ReactDOM.createRoot(root).render(
//root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/sorteo" element={<Sorteo />} />
      <Route path="/news" element={<News />} />

    </Routes>
  </BrowserRouter>
);
