import React from "react";
import {Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import Header from "components/news/Header";
import Premios from "components/news/noticias";
import Images from "components/news/Images";

function News() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <Navigation />
      <div className="wrapper">
        <Header />
        <Images />
        <Premios />
        <div className="section news-section">
          <Container>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg4.jpg") + ")"
                    }}
                  >
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg3.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    ¿Por qué apoyar a Walter?
                  </h3>
                  <p>
                    Walter es un atleta ciego con una determinación inquebrantable.
                    Su objetivo es conquistar el majestuoso Aconcagua, un desafío que
                    cambiará su vida para siempre. La recaudación de esta rifa es
                    esencial para ayudar a Walter a alcanzar su sueño y demostrar que
                    no hay límites para la voluntad humana.
                  </p>
                  <h3>
                    ¿Cómo puedes colaborar?
                  </h3>
                  <p>
                    Es sencillo y gratificante. Compra tus números de rifa hoy mismo.
                    Cada boleto que adquieras nos acerca un paso más a hacer realidad
                    el sueño de Walter. Además, estarás participando por emocionantes
                    premios que podrían ser tuyos.
                  </p>
                  <h3>
                    ¡Únete a nuestra causa!
                  </h3>
                  <p>
                    Tu generosidad no solo contribuirá a que Walter alcance la cumbre del
                    Aconcagua, sino que también te dará la oportunidad de ganar premios
                    que te dejarán sin aliento. ¡Hagamos juntos que este sueño se convierta en realidad!
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>


        <div className="section section-contact-us text-center">
          <Container>
            < h3 className="title">
              Comprá tus númeritos ahora y formá parte de esta emocionante aventura.
              ¡Juntos, podemos hacer posible lo imposible!
            </h3>
            <h4 className="title">
              Fecha del Sorteo: 20 de diciembre de 2023
            </h4>
						<Button
							className="mx-2 btn-round"
							color="info"
							href="/Sorteo-Aconcagua-Con-Sentidos.pdf"
							size="lg"
							target="_blank"
						>
							<i className="now-ui-icons arrows-1_share-66 mr-1"></i>
							Lista de premios en PDF
						</Button>
            <Button
							className="mx-2 btn-round"
							color="info"
							href="/sorteo"
							size="lg"
						>
							<i className="now-ui-icons arrows-1_share-66 mr-1"></i>
							Instrucciones
						</Button>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default News;
