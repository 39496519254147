import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function Navigation() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand  tag={Link}
              to="/"
              id="navbar-brand" onClick={(e) => {
                if (window.location.pathname === '/'){
                  e.preventDefault(); 
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}}
            >
              Aconcagua con sentidos
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Un desafío sin límites
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
             
            <Nav navbar>
           
              <NavItem>
                <NavLink to="/#download-section" tag={Link} onClick={(e) => {
                  if (window.location.pathname === '/'){
                    e.preventDefault(); 
                    document.getElementById("download-section").scrollIntoView({behavior: "smooth" });
                  }}} >
                  <i className="now-ui-icons arrows-1_cloud-download-93"></i>
                  <p>Descargas</p>
                </NavLink>
              </NavItem>

              

              <NavItem>
                <NavLink to="/sorteo" tag={Link} >
                  <i className="now-ui-icons design_app mr-1"></i>
                  <p>Sorteo</p>

                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink to="/news" tag={Link} >
                  <i className="now-ui-icons education_agenda-bookmark mr-1"></i>
                  <p>Noticias</p>

                </NavLink>
              </NavItem>
              
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/walter.al.aconcagua?ref=aconcaguaconsentidos"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Seguinos en Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/walter.al.aconcagua?ref=aconcaguaconsentidos"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Seguinos en Instagram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar >
    </>
  );
}

export default Navigation;
