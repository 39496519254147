import React from "react";

// reactstrap components
import {
  Container,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/bg1.jpg"),
    altText: "Maratón Bs. As. 42km",
    caption: "Maratón Bs. As. 42km"
  },
  {
    src: require("assets/img/bg3.jpg"),
    altText: "1er. puesto. Maratón Bs. As. 42km",
    caption: "1er. puesto. Maratón Bs. As. 42km"
  },
  {
    src: require("assets/img/bg4.jpg"),
    altText: "Cumbre cerro en Vallecito",
    caption: "Cumbre cerro en Vallecito"
  }
  ,
  {
    src: require("assets/img/bg5.jpg"),
    altText: "Cumbre cerro en Vallecito",
    caption: "Cumbre cerro en Vallecito"
  }
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section" id="carousel">
        <Container>
          <div className="title">
            <h2 class="text-center title">Algunos de sus logros</h2>
          </div>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map((item) => {
                  return (
                    <CarouselItem
                    style={{
                      width: "100%",
                      height: "70vh", // Establece la altura al 70vh
                      overflow: "hidden", // Oculta el desbordamiento
                    }}
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h4 style={{textShadow: '0px 0px 5px rgba(0,0,0,0.7)'}}>{item.caption}</h4>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#aconcaguaconsentidos"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#aconcaguaconsentidos"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
        </Container>
      </div>
    </>
  );
}

export default CarouselSection;
