import React from "react";

// reactstrap components
import {

  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navigation from "components/Navigation";
import Footer from "components/Footer";
import Header from "components/sorteo/Header";
import Instrucciones from "components/sorteo/Instrucciones";
import Premios from "components/sorteo/Premios";
import Images from "components/sorteo/Images";

function Sorteo() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <Navigation />
      <div className="wrapper">
        <Header />
        <Images />
        <Premios />
        <div className="section sorteo-section">
          <Container>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg4.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      ¿Qué estamos sorteando?
                      Imagina ganar fabulosos premios mientras haces una buena acción. Desde escapadas
                      de ensueño y regalos de lujo hasta experiencias únicas, hay algo para todos en
                      esta rifa.

                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg3.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    ¿Por qué apoyar a Walter?
                  </h3>
                  <p>
                    Walter es un atleta ciego con una determinación inquebrantable.
                    Su objetivo es conquistar el majestuoso Aconcagua, un desafío que
                    cambiará su vida para siempre. La recaudación de esta rifa es
                    esencial para ayudar a Walter a alcanzar su sueño y demostrar que
                    no hay límites para la voluntad humana.
                  </p>
                  <h3>
                    ¿Cómo puedes colaborar?
                  </h3>
                  <p>
                    Es sencillo y gratificante. Compra tus números de rifa hoy mismo.
                    Cada boleto que adquieras nos acerca un paso más a hacer realidad
                    el sueño de Walter. Además, estarás participando por emocionantes
                    premios que podrían ser tuyos.
                  </p>
                  <h3>
                    ¡Únete a nuestra causa!
                  </h3>
                  <p>
                    Tu generosidad no solo contribuirá a que Walter alcance la cumbre del
                    Aconcagua, sino que también te dará la oportunidad de ganar premios
                    que te dejarán sin aliento. ¡Hagamos juntos que este sueño se convierta en realidad!
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>


        <div className="section section-contact-us text-center">
          <Container>
            < h2 className="title">
              Comprá tus númeritos ahora y formá parte de esta emocionante aventura.
              ¡Juntos, podemos hacer posible lo imposible!
            </h2>
            <h4 className="title">
              Fecha del Sorteo: 20 de diciembre de 2023
            </h4>

          </Container>

        </div>
        <Instrucciones />
        <Footer />
      </div>
    </>
  );
}

export default Sorteo;
