/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function Footer() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Diseñado por {" "}
          <a
            href="https://www.aconcaguaconsentidos.ar?ref=aconcaguaconsentidos"
            target="_blank"
          >
            DobleA
          </a>
          . Coded by{" "}
          <a
            href="https://www.github.com/matigrub?ref=aconcaguaconsentidos"
            target="_blank"
          >
            MatiGrub
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
