import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Navigation from "components/Navigation";
import Header from "components/index/Header";
import Footer from "components/Footer";
import Images from "components/index/Images";
import Carousel from "components/index/Carousel";
import Download from "components/index/Download";

function Index() {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (window.location.hash === "#download-section") {
      scrollToDownloadSection();
    }
    if (window.location.hash === "") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const scrollToDownloadSection = () => {
    const downloadSection = document.getElementById("download-section");
    if (downloadSection) {
      downloadSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Navigation />
      <div className="wrapper">
        <Header />
        <div className="main">
          <Images />
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center my-6" md="8">
                <h2 className="title">Apoya a Walter</h2>
                <h4 className="description">
                  Vos podés marcar la diferencia en la vida de Walter,
                  un valiente atleta ciego con un sueño gigante, ¡Acompañanos
                  en nuestra <b>Gran Rifa a Beneficio</b> y sé parte de este emocionante viaje!
                </h4>
                <Button className="bg-info btn-round px-5 py-3" size="lg" tag={Link} to="/sorteo">
                  <div>QUIERO SABER M&Aacute;S <i className="hover:animate-bounce now-ui-icons arrows-1_minimal-right"></i></div>

                </Button>
              </Col>
            </Row>
          </Container>
          <Carousel />
          <Download />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Index;
