import React from "react";
import { Col, Row } from "reactstrap";

function Header() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    < >
      <div className="page-header page-header-small" >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg6.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center" >
            <Row  >
              <Col className="ml-auto mr-auto text-center" md="8">
                <h1 className="title">Gran rifa a beneficio</h1>
                
                <p className="text-2xl" >
                  Vos podés marcar la diferencia en la vida de Walter,
                  un valiente atleta ciego con un sueño gigante, ¡Acompañanos
                  en nuestra Gran Rifa a Beneficio y sé parte de este emocionante viaje!
                </p>
                <h5 className="title">
                  Fecha del Sorteo: 20 de diciembre de 2023
                </h5>                
                <h5>
                  Sorteo en vivo por las redes sociales
                </h5>
              </Col>
              
            </Row>
        </div>
      </div>
    </>
  );
}

export default Header;
